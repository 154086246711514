var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d4cbae7e4c9e9fe7073dd5682d3a2d125b6e4982"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://efa48f40d44d8448145f39c181f9aa86@o4506435970662400.ingest.sentry.io/4506435972300800",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1,
  tracePropagationTargets: ["localhost", /^\//, process.env.API],
});
